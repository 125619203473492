var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormInputWidget',{attrs:{"id":_vm.inputId,"notRequired":_vm.notRequired,"label":_vm.label,"hideLabel":_vm.hideLabel,"label-is-fake":"","hasError":_vm.hasError,"errorMsg":_vm.errorMsg},scopedSlots:_vm._u([(_vm.$scopedSlots.label)?{key:"label",fn:function(ref){
var label = ref.label;
var notRequired = ref.notRequired;
return [_vm._t("label",null,{"label":label,"notRequired":notRequired})]}}:null],null,true)},[_c('FormInputRadio',{attrs:{"id":_vm.inputId,"notRequired":_vm.notRequired,"options":_vm.options,"optionId":_vm.optionId,"optionLabel":_vm.optionLabel,"optionValue":_vm.optionValue,"value":_vm.value},on:{"input":_vm.emitInput},scopedSlots:_vm._u([(_vm.$scopedSlots.default)?{key:"default",fn:function(ref){
var option = ref.option;
var optionValue = ref.optionValue;
var optionLabel = ref.optionLabel;
return [_vm._t("default",null,{"option":option,"optionValue":optionValue,"optionLabel":optionLabel})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }