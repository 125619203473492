<template>
  <fieldset>
    <div class="form-check" v-for="(option, i) in options" :key="option[optionId]">
      <input class="form-check-input" type="radio" :id="inputId + '-' + i" :value="option[optionValue]" :disabled="disabled" v-model="internalValue" @change="onChange">
      <label class="form-check-label" :for="inputId + '-' + i">
        <slot name="default" :i="i" :option="option" :optionLabel="optionLabel" :optionValue="optionValue">
          {{ option[optionLabel] }}
        </slot>
      </label>
    </div>
  </fieldset>
</template>

<script>

import formInputRadio from './mixins/formInputRadio.js';

export default {
  mixins: [formInputRadio],
};

</script>
