import formSelectPropsMixin from './formSelectProps.js';

export default {
  mixins: [formSelectPropsMixin],
  props: {
    optionId: {
      type: String,
      default: 'id',
    },
  },
};
