<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :label="label" :hideLabel="hideLabel" label-is-fake
    :hasError="hasError" :errorMsg="errorMsg"
  >
    <template v-if="$scopedSlots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <FormInputRadio :id="inputId" :notRequired="notRequired" :options="options" :optionId="optionId" :optionLabel="optionLabel" :optionValue="optionValue" :value="value" @input="emitInput">
      <template v-if="$scopedSlots.default" #default="{ option, optionValue, optionLabel }">
        <slot name="default" :option="option" :optionValue="optionValue" :optionLabel="optionLabel" />
      </template>
    </FormInputRadio>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget.js';
import formInputRadioPropsMixin from '../mixins/formInputRadioProps.js';

import FormInputWidget from '../Widgets/FormInputWidget.vue';
import FormInputRadio from '../FormInputRadio.vue';

export default {
  mixins: [formInputWidgetMixin, formInputRadioPropsMixin],
  components: {
    FormInputWidget,
    FormInputRadio,
  },
};

</script>
