<template>
  <FormInputRadioWidget :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg" :options="pricingOptions" :value="value" @input="$emit('input', $event)">
    <template #default="{ option }">
      <div style="margin-bottom: 20px">
        <strong>{{ option.name }}</strong><br>
        <template v-if="option.activation_price">Costo di attivazione {{ option.activation_price | currency }}</template>
        <template v-else>Nessun costo di attivazione.</template>
        <br>
        <template v-if="option.monthly_price">{{ option.monthly_price | currency }} al mese.</template>
        <template v-else>Nessun costo fisso mensile.</template>
        <br>
        {{ option.fixed_fee | currency }} + {{ option.percent_fee | percentage }}% per transazione.
      </div>
    </template>
  </FormInputRadioWidget>
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget.js';
import formatPercentage from '@/filters/formatPercentage.js';
import formatCurrency from '@/filters/formatCurrency.js';

import FormInputRadioWidget from '@/libs/Form/components/Inputs/Widgets/FormInputRadioWidget.vue';

export default {
  mixins: [formInputWidgetMixin],
  components: { FormInputRadioWidget },
  filters: {
    currency: formatCurrency,
    percentage: formatPercentage,
  },
  props: {
    showAll: {
      type: Boolean,
      default: false,
    },
    pricings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pricingOptions () {
      let pricings = [];

      if (this.pricings === null) {
        return pricings;
      }

      if (this.showAll) {
        pricings.push({ value: null, label: 'Tutti' });
      }

      pricings = [...pricings, ...this.pricings.map(p => {
        p.value = p.id;
        p.label = p.name;

        return p;
      })];

      return pricings;
    },
  },
  watch: {
    pricings () {
      if (this.value) {
        return;
      }

      const defaultPricing = this.pricings.find(p => p.default);
      if (!defaultPricing) {
        return;
      }

      this.$emit('input', defaultPricing.id);
    },
  },
};

</script>
