<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row" v-if="onOnboarding">
      <FormInputTextWidget
        class="col-lg-6"
        label="Coupon promozionale"
        not-required
        v-model="settings.coupon"
        @input="listCouponPricings"
        :errorMsg="errorHash.coupon"
      />
    </div>

    <div class="row" v-if="onOnboarding">
      <SelectPricingWidget
        class="col-12"
        label="Pacchetto eziCommerce"
        v-model="settings.pricing"
        :pricings="pricings"
      />
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-6"
        label="Nome e cognome / Ragione sociale"
        v-model="settings.company_name"
        :errorMsg="errorHash.company_name"
        @input="removeFieldError('company_name')"
        not-required
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Iban"
        v-model="settings.iban"
        :errorMsg="errorHash.iban"
        @input="removeFieldError('iban')"
        not-required
      />
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-6"
        label="Partita IVA"
        v-model="settings.vat_code"
        :errorMsg="errorHash.vat_code"
        @input="removeFieldError('vat_code')"
      />

      <FormInputTextWidget
        class="col-lg-6"
        label="Codice fiscale"
        v-model="settings.tax_code"
        :errorMsg="errorHash.tax_code"
        @input="removeFieldError('tax_code')"
      />

      <SelectInvoiceModeWidget
        class="col-lg-6"
        label="Fatturazione elettronica"
        v-model="settings.invoice_mode"
        not-required
      />

      <FormInputTextWidget
        v-if="settings.invoice_mode === 'sdi'"
        class="col-lg-6"
        label="SDI"
        v-model="settings.sdi"
        :errorMsg="errorHash.sdi"
        @input="removeFieldError('sdi')"
        not-required
      />
      <FormInputTextWidget
        v-else
        class="col-lg-6"
        label="PEC"
        v-model="settings.pec"
        :errorMsg="errorHash.pec"
        @input="removeFieldError('pec')"
        not-required
      />

      <div class="col-12" v-if="edit">
        <FormInputCheckbox not-required v-model="settings.main_email_as_invoice_email">Usare l'email generale per ricevere comunicazioni di fatturazione?</FormInputCheckbox>
        <FormInputTextWidget
          v-if="!settings.main_email_as_invoice_email"
          label="Email per ricevere comunicazioni di fatturazione"
          v-model="settings.invoice_email"
          :errorMsg="errorHash.invoice_email"
          @input="removeFieldError('invoice_email')"
        />
      </div>
    </div>

    <div v-if="edit" class="row">
      <div class="col-12">
        <FormInputCheckbox not-required v-model="settings.main_email_as_receipt_email">Usare l'email generale per ricevere comunicazioni dei pagamenti?</FormInputCheckbox>
        <FormInputTextWidget
          v-if="!settings.main_email_as_receipt_email"
          label="Email per ricevere comunicazioni dei pagamenti"
          v-model="settings.receipt_email"
          :errorMsg="errorHash.receipt_email"
          @input="removeFieldError('receipt_email')"
        />

        <FormInputCheckbox not-required v-model="settings.allow_direct_payment">Abilitare il pagamento diretto al negozio?</FormInputCheckbox>
      </div>

      <FormInputTextWidget
        class="col-lg-6"
        label="Codice di riscatto"
        v-model="settings.redeem_code"
        :errorMsg="errorHash.redeem_code"
        @input="removeFieldError('redeem_code')"
      />
    </div>

    <div class="row">
      <div class="col-auto mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
      <div class="col-auto mt-3" v-if="onOnboarding">
        <router-link :to="{ name: 'logout' }" class="btn btn-outline-secondary px-5">
          Esci e riprendi dopo
        </router-link>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import axios from 'axios';
import debounce from 'tiny-debounce';
import { emailIsValid } from '@/helpers/validation.js';
import validateMixin from '@/libs/Form/mixins/validate.js';

import rolesMixin from '@/mixins/roles.js';
import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputCheckbox from '@/libs/Form/components/Inputs/FormInputCheckbox.vue';
import SelectInvoiceModeWidget from '@/views/components/Form/Widgets/SelectInvoiceModeWidget.vue';
import SelectPricingWidget from '@/views/components/Form/Widgets/SelectPricingWidget.vue';

const CancelToken = axios.CancelToken;

export default {
  mixins: [validateMixin, rolesMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FormInputCheckbox,
    SelectInvoiceModeWidget,
    SelectPricingWidget,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    settings: {
      type: Object,
      default: () => ({
        coupon: null,
        company_name: null,
        tax_code: null,
        vat_code: null,
        iban: null,
        invoice_mode: 'sdi',
        sdi: null,
        pec: null,
        main_email_as_receipt_email: true,
        receipt_email: null,
        main_email_as_invoice_email: true,
        invoice_email: null,
        allow_direct_payment: true,
        redeem_code: null,
        pricing: null,
      }),
    },
    feedback: {
      type: Boolean,
      default: null,
    },
    onOnboarding: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultPricings: [],
    couponPricings: [],
    cancelTokenSource: null,
  }),
  computed: {
    pricings () {
      if (this.couponPricings.length > 0) {
        return this.couponPricings;
      }

      return this.defaultPricings;
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.settings.tax_code && !this.settings.vat_code) {
        this.addError('Inserisci la partita IVA o il codice fiscale', 'tax_code');
      }

      if (this.edit && (!this.settings.main_email_as_invoice_email && !emailIsValid(this.settings.invoice_email))) {
        this.addError('Inserisci l\'email per ricevere comunicazioni di fatturazione', 'invoice_email');
      }

      if (this.edit) {
        if (!this.settings.main_email_as_receipt_email && !emailIsValid(this.settings.receipt_email)) {
          this.addError('Inserisci l\'email per ricevere comunicazioni dei pagamenti', 'receipt_email');
        }

        if (!this.settings.redeem_code || this.settings.redeem_code.length !== 4) {
          this.addError('Inserisci il codice di riscatto', 'redeem_code');
        }
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const settingsToReturn = { ...this.settings };

      this.$emit('submitForm', settingsToReturn);
    },
    listDefaultPricings () {
      this.$api.listPricings()
        .then(res => {
          this.defaultPricings = res.data.pricings || [];
        })
        .catch(this.$log.error)
      ;
    },
    listCouponPricings: debounce(function () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.cancelTokenSource = null;
      this.removeFieldError('coupon');

      if (!this.settings.coupon) {
        this.couponPricings = [];

        return;
      }

      this.cancelTokenSource = CancelToken.source();
      this.$api.listPricings({ coupon: this.settings.coupon }, this.cancelTokenSource.token)
        .then(res => {
          this.couponPricings = res.data.pricings || [];

          if (this.couponPricings.length === 0) {
            this.addError('Coupon non valido', 'coupon');
          }
        })
        .catch(this.$log.error)
      ;
    }, 200),
  },
  watch: {
    pricings (pricings) {
      if (!this.settings.pricing) {
        return;
      }

      const pricing = pricings.find(p => p.id === this.settings.pricing);
      if (!pricing) {
        this.settings.pricing = pricings.length > 0 ? pricings[0].id : null;
      }
    },
  },
  mounted () {
    if (this.onOnboarding) {
      this.listDefaultPricings();
    }
  },
};

</script>
